import styled from "styled-components";

export const Container = styled.div`
   width:100%;
    padding: 0% 150px 0 10%;
    min-height:100vh;
    color:white;
    backdrop-filter: blur(5px);
    display: ${props => props.enabled ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: 40px;
    opacity:${props => props.onScreen ? `1` : "0"};
    background: linear-gradient(#485563,#29323c);
    transition: opacity 1s ease-in-out;

    @media only screen and  (min-width:300px) and (max-width:1299px){    
        padding: 0% 10% 0 10%;
    }
`


export const Title = styled.h1`
    font-size:clamp(1rem,8vw,5rem);
    width: 90%;
    text-align: left;
`

export const SkillsBlock = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit,1fr);
    grid-gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
`

export const SkillBlock = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content:center;
    flex-direction: column;
    grid-row-gap: 20px;
`

export const SkillTitle = styled.h3`
    font-size:clamp(1rem,5vw,2.5rem);
    position: relative;

    &::after{
        content: "";
        position: absolute;
        bottom:-5px;
        left: 0px;
        width: 80%;
        height: 5px;
        background-color: var(--golden);
        border-radius: 0.2em;
    }
`

export const SkillDescription = styled.article`

`

export const SkillIcon = styled.span`

`

export const SkillName = styled.span`

`

export const SkillNameList = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    padding-left: 20px;
`
