import React,{ useEffect, useRef, useState } from 'react';
import { Container,AnimationBg,AnimationContainer,IntroSection,Summary,Avator,Concat,Experience } from "./Style";
import { AiOutlineLink } from "react-icons/ai";
import useOnScreen from "hooks/useOnScreen"

const BlobAnimation = ({translate = {top:"152.7819366455078",left:"-10.415725708007812"}}) => {
    return (
        <AnimationContainer>
            <svg viewBox="0 0 800 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height={"100%"} width="100%" id="blobSvg">
                <g transform={`translate(${translate.top}, ${translate.left})`}>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" style={{stopColor: "#061161"}}></stop>
                            <stop offset="100%" style={{stopColor: "#780206"}}></stop>
                        </linearGradient>
                    </defs>
                    <path fill="url(#gradient)">
                        <animate
                            attributeName="d"
                            dur="10000ms"
                            repeatCount="indefinite"
                            values="
                        M433,298Q400,346,366,387.5Q332,429,273.5,461.5Q215,494,180.5,432Q146,370,94,340.5Q42,311,60.5,255.5Q79,200,108.5,160Q138,120,180,88Q222,56,276.5,64.5Q331,73,364,114Q397,155,431.5,202.5Q466,250,433,298Z;

                        M447,308.5Q432,367,392,419.5Q352,472,290,436Q228,400,185,387.5Q142,375,106.5,339Q71,303,64,248Q57,193,76.5,132.5Q96,72,155.5,38Q215,4,275,34.5Q335,65,371,107Q407,149,434.5,199.5Q462,250,447,308.5Z;

                        M436.64378,312.55243Q444.60757,375.10487,383.89513,392.93404Q323.18269,410.76321,270.63837,441.40325Q218.09405,472.04328,184.09946,418.56867Q150.10487,365.09405,98.8454,337.19892Q47.58593,309.30379,40.58593,248Q33.58593,186.69621,61.64107,125.74594Q89.69621,64.79567,155.95027,61.86434Q222.20433,58.933,283.10487,51.61028Q344.00541,44.28756,371.45027,99.3454Q398.89513,154.40325,413.78756,202.20162Q428.67998,250,436.64378,312.55243Z;

                        M424.55468,305.99499Q424.10935,361.98998,386.06469,412.76628Q348.02003,463.54257,282.0722,475.7116Q216.12437,487.88063,179.62187,430.75626Q143.11937,373.63189,119.89065,334.37312Q96.66193,295.11436,59.89065,239.24374Q23.11937,183.37312,67.00501,136.38314Q110.89065,89.39315,166.94532,75.88314Q223,62.37312,269.98998,83.27128Q316.97997,104.16944,365.80843,124.33097Q414.63689,144.49249,419.81845,197.24624Q425,250,424.55468,305.99499Z;

                        M443.93686,307.01316Q427.44211,364.02632,385.69474,409.55789Q343.94736,455.08946,285.2079,435.80786Q226.46843,416.52626,183.02632,396.97103Q139.58421,377.41579,104.88157,340.03948Q70.17892,302.66316,55.34999,245.65001Q40.52107,188.63685,69.2079,131.55789Q97.89473,74.47893,161.25263,73.87631Q224.61053,73.27369,279.67632,68.97893Q334.74212,64.68418,369.83158,107.46577Q404.92104,150.24737,432.67632,200.12369Q460.4316,250,443.93686,307.01316Z;

                        M424.01986,300.68723Q407.04775,351.37447,374.90236,402.32269Q342.75697,453.27092,282.09362,451.49409Q221.43026,449.71726,169.99598,425.59173Q118.5617,401.46619,86.87447,354.36856Q55.18723,307.27092,41.00993,245.98605Q26.83262,184.70118,67.60756,135.67731Q108.38251,86.65343,165.55579,74.51395Q222.72908,62.37447,284.01395,51.63144Q345.29882,40.88842,389.16738,86.60355Q433.03593,132.31868,437.01395,191.15934Q440.99196,250,424.01986,300.68723Z;

                        M433,298Q400,346,366,387.5Q332,429,273.5,461.5Q215,494,180.5,432Q146,370,94,340.5Q42,311,60.5,255.5Q79,200,108.5,160Q138,120,180,88Q222,56,276.5,64.5Q331,73,364,114Q397,155,431.5,202.5Q466,250,433,298Z;
                        "
                        >
                        </animate>
                    </path>
                </g>
            </svg>
        </AnimationContainer>

    )
}

const About = ({enabled = false}) => {
    const containerRef = useRef();
    const onScreen = useOnScreen(containerRef,"0%");
    const [animationCoord,setAnimationCoord] = useState({
        top:150,
        left:0
    });

    return (
        <Container ref={containerRef} onScreen={onScreen} enabled={enabled}>
            <IntroSection>
                <Summary>
                    <h1>About Me</h1>
                    <h4>
                        Hi 我是 Sean ，喜歡網站開發與設計，個性活潑喜歡與人溝通，我相信相互學習討論是提升自己能力最好的方式 。
                    </h4>
                    <Experience>
                        <h4>國立虎尾科技大學 資訊工程系</h4>
                        <h4>叡揚資訊 / 實習 ~ 正職 RD &nbsp;&nbsp;( 2021-02 ~ 2021-11 )</h4>
                        {/* <h4>馬祖義務役 &nbsp;&nbsp;( 2022-02 ~ 2022-05 )</h4> */}
                        <h4>北宸資訊 / 前端工程師 &nbsp;&nbsp;( 2022-07 ~ 至今 )</h4>
                    </Experience>
                    <Concat>
                        <a href='https://github.com/SinHongChen'>Github<AiOutlineLink/></a>
                        <a href='https://www.facebook.com/'>Facebook<AiOutlineLink/></a>
                        <a href='https://www.cakeresume.com/me/1e6778'>CakeResume<AiOutlineLink/></a>
                        <a href='https://pda.104.com.tw/profile/preview?vno=75s9dnnww'>104<AiOutlineLink/></a>
                    </Concat>
                </Summary>
                <Avator src="./avator.png"/>
            </IntroSection>
            {/* <AnimationBg>
                <BlobAnimation translate={animationCoord}/>
            </AnimationBg> */}
        </Container>
    )
}

export default About
