import styled,{ keyframes } from "styled-components";
import { HashLink as Link } from 'react-router-hash-link';

export const NavbarAnimation = keyframes`
    from {
      transform:translateX(-10vw);
    }

    to {
       transform:translateX(0px);
    }
`

export const LeftNavbarContainer = styled.ul`
    position: fixed;
    height: fit-content;
    width: 80px;
    display: flex;
    justify-content: center;
    grid-gap: 45px;
    z-index: 10000;
    transition: transform 1s linear;
    animation: ${NavbarAnimation} 0.5s linear;


    @media only screen and  (min-width:1300px){ 
        left: 1%;
        top: 40%;   
        align-items: flex-start;
        flex-direction: column;
    }
    
    @media only screen and  (min-width:300px) and (max-width:1299px){    
        display: none;
    }
    
`

export const BlockLink = styled(Link)`
    display: block;
    color:white;
    font-size:15px;
    font-weight:bold;
    width: 100%;

    &:hover{
        cursor: pointer;
        color:var(--golden);
        border-bottom: 1px solid white;
    }

    @media only screen and  (min-width:1300px){ 
        
        /* color : ${props => props.isSelected ? "var(--golden)" : "white"}; */
    }

    @media only screen and  (min-width:300px) and (max-width:1299px){    
        display: none;
    }
`