import styled from "styled-components";


export const Container = styled.div`
    width:100vw;
    height:100vh;
    position: relative;
    align-items: center;
    justify-content: center;
    display: ${props => props.enabled ? "flex" : "none"};
    opacity:${props => props.onScreen ? `1` : "0"};
    transition: opacity 0.3s linear;
`

export const AnimationBg = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-color: #6190E8;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#485563,#29323c);
`

export const IntroSection = styled.div`
    position:fixed;
    left: 10vw;
    right:10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap-reverse;
    grid-row-gap: 20px;
    grid-column-gap: 2vw;
    width: 80vw;
    height: fit-content;
    z-index: 2;
    color: white;

    & h1{
        font-size:clamp(1rem,8vw,5rem);
    }

    & h4{
        font-size: clamp(0.8rem,1.8vh,1.8rem);
    }

    & a{
        /* border-bottom: #fca103 1px solid; */
        padding: 8px 10px;
    font-size: clamp(0.8rem,1.8vh,1.8rem);
    display: block;
    position: relative;
    transition: background 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3px;
    color: white;

    &:hover{
        background: #cc8023;
        border-radius: 0.2em;
        cursor: pointer;
    }
    }
`

export const Experience = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-left: 1px solid #cc8023;
    padding-left: 10px;
`

export const Concat = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 5px;
    border-left: 1px solid #cc8023;
`

export const Summary = styled.div`
    display: grid;
    grid-template-rows: clamp(1rem,8vw,5rem) 1fr auto auto;
    grid-row-gap: 20px;
`

export const Avator = styled.img`
    width: clamp(100px,40vh,350px) ;
    height: clamp(100px,40vh,350px) ;
    object-fit: contain;
    border-radius: 50%;
    display: block;
`


export const AnimationContainer = styled.div`
    width: 100%;
    height: 100%;
`