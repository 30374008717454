import styled from "styled-components";
import { HashLink as Link } from 'react-router-hash-link';

export const TopNavbarContainer = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width:100%;
    height:fit-content;
    z-index:var(--top-navbar-index);
    color: white;

    @media only screen and  (min-width:1300px){ 
        display: none;
    }
`

export const MenuTrigger = styled.a`
    color:white;
    font-size: 32px;
    display: block;
    padding:15px;
    width:32px;
    height:32px;

    &:hover{
        cursor:pointer;
    }
`

export const MenuCloseBtn = styled(MenuTrigger)`
    position:absolute;
    top:0px;
    left:0px;
`

export const Menu = styled.ul`
    position:absolute;
    height:100vh;
    top:0px;
    left:0px;
    width:${props => props.enabled ? "100vw" : "0px"};
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap:5%;
`

export const BlockLink = styled(Link)`
    width: 100%;
    text-align: center;
    font-size: clamp(1em,5vh,2em);
    color : ${props => props.isSelected ? "var(--golden)" : "white"};

    &:hover{
        color:var(--hover-color);
    }
`