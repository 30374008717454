import React, { useEffect } from 'react';
import { LeftNavbarContainer,BlockLink } from "./Style";
import { useLocation } from 'react-router-dom'

const LeftNavbar = ({ navInfos }) => {
    const location = useLocation();

    return (
        <LeftNavbarContainer>
            {navInfos?.map((navInfo,index)=>{
                return <BlockLink key={index} to={`/${navInfo.path}`} isSelected={location.hash === navInfo.path}>{navInfo.pathName}</BlockLink>
            })}
        </LeftNavbarContainer>
    )
}

export default LeftNavbar
