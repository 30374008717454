import React, { useState } from 'react';
import { TopNavbarContainer,Menu,MenuTrigger,MenuCloseBtn,BlockLink } from "./Style";
import { useLocation } from 'react-router-dom';
import { AiOutlineClose,AiOutlineMenu } from "react-icons/ai";

const TopNavbar = ({navInfos}) => {
    const location = useLocation();
    const [enabledMenu,setEnabledMenu] = useState(false);

    return (
        <TopNavbarContainer>
            <MenuTrigger onClick={()=>{setEnabledMenu(true)}}><AiOutlineMenu/></MenuTrigger>
            <Menu enabled={enabledMenu}>
                <MenuCloseBtn onClick={()=>{setEnabledMenu(false)}}><AiOutlineClose/></MenuCloseBtn>
                {navInfos?.map((navInfo,index)=>{
                    return <BlockLink onClick={()=>{setEnabledMenu(false)}} key={index} to={`/${navInfo.path}`} isSelected={location.hash === navInfo.path}>{navInfo.pathName}</BlockLink>
                })}
            </Menu>
        </TopNavbarContainer>
    )
}

export default TopNavbar
