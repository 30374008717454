import React, { useEffect,useRef } from 'react';
import { ParallaxBannerContainer, Title, HomeBgPicture } from "./Style";
import { useNavigate } from "react-router-dom";
import { useOnScreen } from "hooks";

const ParallaxBanner = () => {
    const navigate = useNavigate();
    const bannerRef = useRef();
    const onScreen = useOnScreen(bannerRef,"-50%");

    const initialParallax = ()=>{
        let title = document.getElementById("title");
        let moutain1 = document.getElementById("moutain1");
        let moutain2 = document.getElementById("moutain2");

        window.addEventListener("scroll", () => {
            let value = window.scrollY;
            title.style.top = value * 0.6 + "px";
            moutain1.style.bottom = 30 - value * 0.15 + "px";
            moutain2.style.top = value * 0.15 + "px";
        })
    }

    useEffect(()=>{
        initialParallax();
    },[]);

    useEffect(()=>{
        if(onScreen){
            navigate(`/#top`);
        }
    },[onScreen]);

    return (
        <ParallaxBannerContainer ref={bannerRef}>
            <HomeBgPicture src="./images/sky_red.jpeg" />
            <HomeBgPicture style={{ transform: "scale(-1,1)", bottom: "30px" }} id="moutain1" src="./images/moutain_1.png" />
            <Title id="title">PORTFOLIO</Title>
            <HomeBgPicture id="moutain2" src="./images/moutain_1.png" />
        </ParallaxBannerContainer>
    )
}

export default ParallaxBanner
