import React, { useEffect, useRef, useState } from 'react';
import { HomeContainer, Sections,BgColorTheme } from "./Style";
import { LeftNavbar,TopNavbar,SideProject,About,Skill } from "components";
import { useLocation,useNavigate } from 'react-router-dom';

const Home = () => {
    const location = useLocation();
    const [currentPath,setCurrentPath] = useState("");    

    const navInfos = [
        {path:"#about",pathName:"AboutMe"},
        {path:"#skill",pathName:"Skill"},
        {path:"#projects",pathName:"Projects"}
    ]

    useEffect(() => {
        if(location.hash){
            setCurrentPath(location.hash);
        }
    }, [location.hash]);

    return (
        <HomeContainer >
            <LeftNavbar navInfos={navInfos}/>
            <TopNavbar navInfos={navInfos}/>
            <About enabled={currentPath === "#about" || currentPath === ""}/>
            <Skill enabled={currentPath === "#skill"}/>
            <SideProject enabled={currentPath === "#projects"}/>

        </HomeContainer>
    )
}

export default Home
