import styled from "styled-components";

export const ParallaxBannerContainer = styled.div`
    width:100%;
    height:100vh;
    position: relative;
    overflow:hidden;
    
    &:before{
        content: "";
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        height:100px;
        background:linear-gradient(to top,rgb(35, 37, 38), transparent);
        z-index:1000000;
    }
`

export const HomeBgPicture = styled.img`
    height:100%;
    width:100%;
    object-fit: cover;
    position: absolute;
    bottom:0px;
    left:0px;
`

export const Title = styled.h1`
    position: absolute;
    color:white;
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align:center;
    font-size:clamp(1rem,13vw,8rem);
    font-family: 'Bangers', cursive;
    font-family: 'Bebas Neue', cursive;
    font-family: 'Fjalla One', sans-serif;
    font-family: 'Fuzzy Bubbles', cursive;
    font-family: 'Lobster', cursive;
    font-family: 'Moo Lah Lah', cursive;
    font-family: 'Noto Serif TC', serif;
    font-family: 'Sora', sans-serif;
`