import React,{ useEffect, useRef, useState } from 'react';
import { Navbar,ZoomInBlock,ZoomInImage,NavbarLink,Container,ProjectContainer,ProjectLinkList,ProjectSummary,ProjectTitle,ProjectImage,ProjectImageList,ProjectLink } from "./Style";
import useOnScreen from "hooks/useOnScreen";
import { AiOutlineLink } from "react-icons/ai";

const cDriveProjects = [
    {src:"/images/cdrive1.PNG"},
    {src:"/images/cdrive2.PNG"},
    {src:"/images/cdrive3.PNG"},
]

const StrongWise = [
    {src:"/images/strongwise1.PNG"},
    {src:"/images/strongwise2.PNG"},
    {src:"/images/strongwise3.PNG"},
]

const SchoolProjects = [
    {src:"/images/school1.jpg"},
    {src:"/images/school2.jpg"},
    {src:"/images/school3.png"},
]


const PolstarProjects = [
    {src:"/images/polstar1.JPG"},
    {src:"/images/polstar2.JPG"},
    {src:"/images/polstar3.JPG"},
]

const SideProject = ({enabled =false }) => {

    const [currentSection,setCurrentSection] = useState("school");
    const [zoomInImage,setZoomInImage] = useState("");
    const [zoomInEnabled,setZoomInEnabled] = useState(false);

    const containerRef = useRef();
    const onScreen = useOnScreen(containerRef,"0%");

    const projectSchoolRef = useRef();
    const projectStrongwiseRef = useRef();
    const projectCDriveRef = useRef();
    const projectPolnavRef = useRef();

    const projectSchoolOnScreen = useOnScreen(projectSchoolRef,"-50%");
    const projectStrongwiseOnScreen = useOnScreen(projectStrongwiseRef,"-50%");
    const projectCDriveOnScreen = useOnScreen(projectCDriveRef,"-50%");
    const projectPolnavOnScreen = useOnScreen(projectPolnavRef,"-50%");


    const handleImageClick = (e,src)=>{
        setZoomInEnabled(true);
        setZoomInImage(src);
    }

    useEffect(()=>{
        if(projectSchoolOnScreen){
            setCurrentSection("school");
        }

        if(projectStrongwiseOnScreen){
            setCurrentSection("strongwise");
        }

        if(projectCDriveOnScreen){
            setCurrentSection("cdrive");
        }

        if(projectPolnavOnScreen){
            setCurrentSection("polstar");
        }
    },[projectSchoolOnScreen,projectStrongwiseOnScreen,projectCDriveOnScreen,projectPolnavOnScreen])
    
    return <div>
        {zoomInEnabled &&
            <ZoomInBlock onClick={()=>{setZoomInEnabled(false)}}>
                <ZoomInImage src={zoomInImage}/>
            </ZoomInBlock>
        }


    <Navbar  enabled={enabled}>
        <NavbarLink href='#schoolproject' isSelected={currentSection === "school"}>病蟲害辨識</NavbarLink>
        <p/>
        <NavbarLink  href='#strongwiseproject' isSelected={currentSection === "strongwise"}>雄材大智</NavbarLink>
        <p/>
        <NavbarLink  href='#cdriveproject' isSelected={currentSection === "cdrive"}>C-Drive</NavbarLink>
        <p/>
        <NavbarLink  href='#polnavproject' isSelected={currentSection === "polstar"}>Polstar</NavbarLink>
    </Navbar >
    <Container ref={containerRef} onScreen={onScreen} enabled={enabled}>
        <ProjectContainer id="schoolproject" ref={projectSchoolRef} onScreen={projectSchoolOnScreen}>
            <ProjectTitle>
                落花生病蟲害辨識
            </ProjectTitle>
            <ProjectLinkList>
                <ProjectLink target={"_blank"} href='https://sinhongchen.github.io/GitHubPage/templated-roadtrip/index.html'>
                    <p>Website</p><AiOutlineLink/>
                </ProjectLink> 
                {/* &nbsp;&nbsp;/&nbsp;&nbsp;
                <ProjectLink href='https://github.com/web-team-nfu/strongwises'>Github</ProjectLink> */}
            </ProjectLinkList>
            <ProjectSummary>
                大學專題，結合了圖像辨識與自己實作的拍攝平台，可以進行落花生的病蟲害檢視，並透過APP控制與取得辨識結果。
                曾獲得系上專題競賽佳作，專題期間擔任組長，負責規劃專題進度、程式共同開發、簡報製作，專題技術包含圖像辨識訓練、樹梅派IO控制、Android APP 開發、3D列印設計。
            </ProjectSummary>
            <ProjectImageList>
                {SchoolProjects.map((project,index) => {
                    return <ProjectImage onClick={(e)=>{handleImageClick(e,project.src)}} key={index} src={project.src} />;
                })}
            </ProjectImageList>

        </ProjectContainer>
        <ProjectContainer id="strongwiseproject" ref={projectStrongwiseRef} onScreen={projectStrongwiseOnScreen}>
            <ProjectTitle>
                雄材大智
            </ProjectTitle>
            <ProjectLinkList>
                <ProjectLink target={"_blank"} href='https://www.strongwises.com/#/'><p>Website</p><AiOutlineLink/></ProjectLink> 
                <ProjectLink href='https://github.com/web-team-nfu/strongwises'><p>Github</p><AiOutlineLink/></ProjectLink>
            </ProjectLinkList>
            <ProjectSummary>
                大四的時候和朋友嘗試接案，也是首次使用 ReactJs 的專案
            </ProjectSummary>
            <ProjectImageList>
                {StrongWise.map((strongwise,index) => {
                    return <ProjectImage onClick={(e)=>{handleImageClick(e,strongwise.src)}} key={index} src={strongwise.src} />;
                })}
            </ProjectImageList>
        </ProjectContainer>
        <ProjectContainer id="cdriveproject" ref={projectCDriveRef} onScreen={projectCDriveOnScreen}>
            <ProjectTitle>
                C-Drive
            </ProjectTitle>
            <ProjectLinkList>
                {/* <ProjectLink target={"_blank"} href='https://bitter-mountain-957.edge.mysocket.io/mydrive/629f3749b201cc4854430e32'>
                    <p>Website</p><AiOutlineLink/>
                </ProjectLink>  */}
                <ProjectLink href='https://github.com/SinHongChen/C-Drive'><p>Github</p><AiOutlineLink/></ProjectLink>
            </ProjectLinkList>
            <ProjectSummary>
                C-Drive 小型的雲端硬碟，提供了檔案與資料夾上傳、下載、刪除的基本功能，以及加入最愛與移動至垃圾桶等等功能。 (還在持續開發中)
            </ProjectSummary>
            <ProjectImageList>
                {cDriveProjects.map((cdrive,index) => {
                    return <ProjectImage onClick={(e)=>{handleImageClick(e,cdrive.src)}} key={index} src={cdrive.src} />;
                })}
            </ProjectImageList>
        </ProjectContainer>
        <ProjectContainer id="polnavproject" ref={projectPolnavRef} onScreen={projectPolnavOnScreen}>
            <ProjectTitle>
                Polstar 線上地圖
            </ProjectTitle>
            <ProjectLinkList>
                <ProjectLink target={"_blank"} href='https://www.polstarapis.com/'>
                    <p>Website</p><AiOutlineLink/>
                </ProjectLink> 
            </ProjectLinkList>
            <ProjectSummary>
                線上地圖網站，透過 API 金鑰使用地圖上各式應用，使用 Maplibre JS 進行地圖繪製
            </ProjectSummary>
            <ProjectImageList>
                {PolstarProjects.map((cdrive,index) => {
                    return <ProjectImage onClick={(e)=>{handleImageClick(e,cdrive.src)}} key={index} src={cdrive.src} />;
                })}
            </ProjectImageList>
        </ProjectContainer>
    </Container>
    </div>
}
export default SideProject
