import React,{useRef,useEffect} from 'react';
import { useOnScreen } from "hooks";
import { Container,SkillBlock,SkillDescription,SkillsBlock,SkillTitle,SkillNameList,SkillName,SkillIcon,Title } from "./Style";

const Skill = ({enabled =false }) => {
    const containerRef = useRef();
    const onScreen = useOnScreen(containerRef,"0%");

    let frontEndSkills = [
        {name:"Javascript",icon:""},
        {name:"CSS",icon:""},
        {name:"Styled-Component",icon:""},
        {name:"HTML",icon:""},
        {name:"ResfulAPI",icon:""},
        {name:"ReactJs",icon:""},
        {name:"ReduxToolkit",icon:""},
        {name:"NextJs",icon:""},
        {name:"Material",icon:""}
    ]

    let backEndSkills = [
        {name:"NodeJs Express",icon:""},
        {name:"Golang",icon:""},
        {name:"ASP.NET",icon:""},
        {name:"Mongodb",icon:""},
        {name:"MySQL",icon:""},
        {name:"Redis",icon:""}
    ]

    let containerSkills = [
        {name:"Docker",icon:""},
        {name:"Docker-Compose",icon:""}
    ]

    return (
        <Container enabled={enabled} ref={containerRef} onScreen={onScreen}>
            <Title>Skill</Title>
            <SkillsBlock>
                <SkillBlock>
                    <SkillTitle>Front end</SkillTitle>
                    <SkillNameList>
                        {frontEndSkills.map((skill,index)=>{
                            return <p key={index+1}><SkillName>{skill.name}</SkillName></p>
                        })}
                    </SkillNameList>
                    <SkillDescription>

                    </SkillDescription>
                </SkillBlock>
                <SkillBlock>
                    <SkillTitle>Back end</SkillTitle>
                    <SkillNameList>
                        {backEndSkills.map((skill,index)=>{
                            return <p key={index+1}><SkillName>{skill.name}</SkillName></p>
                        })}
                    </SkillNameList>
                    <SkillDescription>
                        
                    </SkillDescription>
                </SkillBlock>
                <SkillBlock>
                    <SkillTitle>Container</SkillTitle>
                    <SkillNameList>
                        {containerSkills.map((skill,index)=>{
                            return <p key={index+1}><SkillName>{skill.name}</SkillName></p>
                        })}
                    </SkillNameList>
                    <SkillDescription>
                        
                    </SkillDescription>
                </SkillBlock>
            </SkillsBlock>
        </Container>
    )
}

export default Skill
