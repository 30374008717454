import styled,{keyframes} from "styled-components";

export const HomeContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: none;
    background: linear-gradient(#485563,#29323c);
`

export const BgColorTheme = styled.div`
    position: absolute;
    width:100%;
    height:100%;
    opacity:${props => props.onScreen ?  `0.8`: `0`};
    transition:all 1.5s linear;
`

export const Sections = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
`

export const TopBlockAnimation = keyframes`
    from {
      width: 90%;
      margin:0px 0px 0px 10%;
    }

    to {
      width: 80%;
      margin: 0 0 0 auto;
    }
`
