import styled from "styled-components";

export const Container = styled.div`
    width:100%;
    padding: 0% 150px 0 10%;
    min-height:100vh;
    color:white;
    backdrop-filter: blur(5px);
    display: ${props => props.enabled ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: 40px;
    opacity:${props => props.onScreen ? `1` : "0"};
    background: linear-gradient(#485563,#29323c);
    transition: all 1s ease-in-out;

    @media only screen and  (min-width:300px) and (max-width:1299px){    
        padding: 0% 10% 0 10%;
    }
`

export const ProjectTitle = styled.h2`
    font-size:clamp(1rem,5vw,2.5rem);
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
`

export const ProjectContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    display: grid;
    grid-template-rows: 30px 40px auto 1fr;
    grid-template-columns:repeat(2,1fr);
    align-items: center;
    justify-items: flex-start;
    grid-row-gap: 20px;
    padding: 10% 0px;
    opacity:${props => props.onScreen ? `1` : "0"};
    transition: all 0.5s linear;

`

export const ProjectImageList = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(280px,auto));
    align-items: center;
    justify-content: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;

`

export const ProjectImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.2em;

    &:hover{
        cursor: zoom-in;
    }
`

export const ZoomInBlock = styled.div`
    position: fixed;
    z-index: var(--zoomin-index);
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    &:hover{
        cursor: zoom-out;
    }
`

export const ZoomInImage = styled.img`
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    pointer-events: none;
`

export const ProjectLink = styled.a`
    padding: 8px 10px;
    font-size: clamp(0.8rem,1.8vh,1.8rem);
    display: block;
    position: relative;
    transition: background 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3px;
    color: white;

    &:hover{
        background: #fca103;
        border-radius: 0.2em;
        cursor: pointer;
    }
`

export const ProjectSummary = styled.p`
    width: 100%;
    text-align: left;
    text-indent: 30px;
    font-size: clamp(0.8rem,1.7vh,2rem);
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
`

export const ProjectLinkList = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
`

export const Navbar = styled.div`
    width: 150px;
    height: 100vh;
    position: fixed;
    top:0px;
    right: 1%;
    z-index: 10000;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    display: ${props => props.enabled ? "flex" : "none"};

    p{
        height: 30px;
        border-right: 2px solid  var(--golden);
    }

    @media only screen and  (min-width:300px) and (max-width:1299px){    
        display: none;
    }

`

export const NavbarLink = styled.a`

    display: block;
    font-size:15px;
    font-weight:bold;
    padding: 6px 18px;
    border-right: 2px solid  var(--golden);
    color : ${props => props.isSelected ? "var(--golden)" : "white"};


    &:hover{
        cursor: pointer;
        color: var(--golden);
    }

`